import { Col, Container, FormControl, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Json } from "../../lib/api-types";
import OfferingCard from "../../components/offerings/OffieringCard";
import { client } from "../../lib/client/client";
import useSWR from "swr";
import { Offerings } from "../../lib/api-types";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import styled from "styled-components";

const Test = styled.div`
  height: 100px;
  width: 100px;
  background-color: #ff0000;
`;

export default function OfferingsPage() {
  useEffect(() => {
    var cwd = document.getElementById("cwd");
    cwd ? (cwd.innerText = "Offerings") : 0;
  });

  const { data, error } = useSWR(
    "/api/offerings",
    client<Json<Offerings>>("json")
  );
  console.log("off data");
  const [search, setSearch] = useState("");

  if (error) return <Error />;
  if (!data) return <Loading />;

  return (
    <Container fluid>
      <FormControl
        type="search"
        placeholder="Search"
        className="mb-3"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <Row>
        {data
          .filter((offering) =>
            offering.title.toLowerCase().includes(search.toLowerCase())
          )
          .map((offering) => (
            <Col sm={12} md={6} lg={4} key={offering._id}>
              <OfferingCard offering={offering} />
            </Col>
          ))}
      </Row>
    </Container>
  );
}
