import Image from "next/image";
import { Button, Card, Table } from "react-bootstrap";
import { Json, Offerings } from "../../lib/api-types";
import Link from "next/link";

export interface OfferingCardProps {
  offering: Json<Offerings[0]>;
}

export default function OfferingCard({ offering }: OfferingCardProps) {
  const coreStats = offering.stats.filter((stat) => stat.core);

  return (
    <Card>
      <div className="card-img-top">
        <Image
          src={offering.image.url}
          alt={offering.image.alt}
          width={offering.image.width}
          height={offering.image.height}
          layout="responsive"
          objectFit="cover"
        />
      </div>
      <Card.Body>
        <Card.Title>{offering.title}</Card.Title>
        <Card.Text>{offering.shortDescription}</Card.Text>
        <Table>
          <tbody>
            {coreStats.map((stat) => (
              <tr key={stat.name}>
                <th>{stat.name}</th>
                <td>{stat.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Link href={`/offerings/${offering._id}`} passHref>
          <Button variant="primary" as="a">
            Trade
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
}
